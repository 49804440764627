import { io } from 'socket.io-client'
import store from '@/store/index.js'

let socket

async function initSocket() {
  const token = store.state.auth.token

  socket = io(process.env.VUE_APP_STORAGE, {
    transports: ['websocket'],
    auth: {
      token
    }
  })

  return socket
}

export { initSocket, socket }
