import api from '@/plugins/api.js'

export default {
  namespaced: true,

  state: {
    credit: undefined
  },

  mutations: {
    setCredit(state, credit) {
      state.credit = credit
    }
  },

  actions: {
    async getCredit({ commit }, creditUuid) {
      const { data } = await api.credits.getOne(creditUuid)
      commit('setCredit', data)
    }
  }
}
