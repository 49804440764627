<template>
  <VApp :class="['auth-layout', `${$route.name}-layout`]">
    <div class="cover" />

    <VContainer fluid>
      <VRow justify="center" style="position: relative; z-index: 2">
        <VCol cols="12" lg="10">
          <div class="section-auth-layout">
            <div class="section-auth-layout-header">
              <h1 class="section-auth-layout-title">Единый Банковский Центр</h1>
            </div>

            <div class="section-auth-layout-body">
              <RouterView />
            </div>
          </div>
        </VCol>
      </VRow>
    </VContainer>

    <SnackbarUi />
  </VApp>
</template>

<script>
import SnackbarUi from '@/components/SnackbarUi.vue'

export default {
  name: 'AuthLayout',

  components: {
    SnackbarUi
  }
}
</script>

<style lang="scss" scoped>
.theme--light.auth-layout {
  background-color: #e7e7e7;
  min-height: 100vh;

  .container {
    position: relative;
    z-index: 2;
  }
}

.cover {
  background-color: #009688;
  height: 50vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.section-auth-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 7rem;

  ::v-deep {
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Roboto', sans-serif !important;
    }
  }
}

.section-auth-layout-body {
  padding-top: 2.55rem;
}

.section-auth-layout-title {
  color: #ffffff;
  font-size: 52px;
  font-weight: 400;
  font-family: Poiret One, serif;
  text-align: center;
}
</style>
