<template>
  <VSnackbar
    :value="snackbar.value"
    :color="snackbar.color"
    @input="$store.commit('setSnackbar', { value: $event })"
  >
    <template #default>
      {{ snackbar.message }}
    </template>

    <template #action>
      <VBtn text @click.prevent="$store.commit('setSnackbar', { value: false })">Закрыть</VBtn>
    </template>
  </VSnackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SnackbarUi',

  computed: {
    ...mapState(['snackbar'])
  }
}
</script>
