import api from '@/plugins/api.js'

export default {
  namespaced: true,

  state: {
    agent: undefined
  },

  mutations: {
    setAgent(state, agent) {
      state.agent = agent
    }
  },

  actions: {
    async getAgent({ commit }, agentUuid) {
      const { data } = await api.agents.getOne(agentUuid)
      commit('setAgent', data)
    }
  }
}
