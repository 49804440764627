import api from '@/plugins/api'

export default {
  namespaced: true,

  state: {
    billingAccount: undefined
  },

  mutations: {
    setBillingAccount(state, billingAccount) {
      state.billingAccount = billingAccount
    },

    clearState(state) {
      state.billingAccount = {}
    }
  },

  actions: {
    async getBillingAccount({ commit }, billingAccountUuid) {
      const { data } = await api.billingAccounts.getOne(billingAccountUuid)

      commit('setBillingAccount', data)
    }
  }
}
