import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.js'
import agents from './agents.js'
import billingAccounts from './billingAccounts.js'
import credits from './credits.js'
import bankGuarantees from '@/store/bankGuarantees.js'
import api from '@/plugins/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: {
      value: false,
      message: '',
      error: false,
      success: false,
      color: ''
    },

    statistics: undefined
  },

  mutations: {
    setSnackbar(state, { value, message, error, success }) {
      state.snackbar.value = value
      state.snackbar.message = message
      state.snackbar.error = error
      state.snackbar.success = success

      if (success) {
        state.snackbar.color = 'primary'
      }

      if (error) {
        state.snackbar.color = 'error'
      }
    },

    setStatistics(state, statistics) {
      state.statistics = statistics
    }
  },

  actions: {
    async getWidgets({ commit }) {
      const { data: statistics } = await api.statistics.getWidgets()

      commit('setStatistics', statistics)
    }
  },

  modules: {
    auth,
    agents,
    billingAccounts,
    credits,
    bankGuarantees
  }
})
