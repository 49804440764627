<template>
  <Component :is="layout" />
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

export default {
  name: 'App',

  components: {
    AuthLayout,
    DefaultLayout
  },

  computed: {
    layout: vm => vm.$route.meta.layout || 'DefaultLayout'
  }
}
</script>
