import api from '@/plugins/api'

export default {
  namespaced: true,

  state: {
    bankGuarantee: undefined
  },

  mutations: {
    setBankGuarantee(state, bankGuarantee) {
      state.bankGuarantee = bankGuarantee
    },

    clearState(state) {
      state.bankGuarantee = undefined
    }
  },

  actions: {
    async getBankGuarantee({ commit }, bankGuaranteeUuid) {
      const { data } = await api.bankGuarantees.getOne(bankGuaranteeUuid)
      commit('setBankGuarantee', data)
    }
  }
}
