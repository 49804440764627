import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  treeShake: true,

  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#4cae50',
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: '#00cae3',
        warning: colors.orange.darken3,
        error: colors.red.accent2,
        success: colors.green.base
      }
    }
  }
})
