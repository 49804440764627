import Vue from 'vue'
import VueRouter from 'vue-router'
import constants from '@/plugins/constants.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'AuthLayout' },
    component: () => import('@/pages/Login.vue')
  },
  {
    path: '/client/:applicationNumber',
    name: 'client-documents',
    props: true,
    meta: { layout: 'AuthLayout' },
    component: () => import('@/pages/ClientDocuments.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/pages/Index.vue')
  },
  {
    path: '/account',
    component: () => import('@/pages/account/AccountRoot.vue'),
    children: [
      {
        path: '/',
        name: 'account',
        component: () => import('@/pages/account/Account.vue')
      },
      {
        path: 'billing',
        name: 'account-billing',
        component: () => import('@/pages/account/AccountBilling')
      }
    ]
  },
  {
    path: '/agents',
    name: 'agents',
    meta: { permission: constants.permissions.agents },
    component: () => import('@/pages/agents/Agents.vue')
  },
  {
    path: '/agents/create',
    name: 'agent-create',
    meta: { permission: constants.permissions.agents },
    component: () => import('@/pages/agents/agent/AgentCreate.vue')
  },
  {
    path: '/agents/:agentId',
    props: true,
    meta: { permission: constants.permissions.agents },
    component: () => import('@/pages/agents/agent/Agent.vue'),
    children: [
      {
        path: '/',
        name: 'agent',
        props: true,
        component: () => import('@/pages/agents/agent/AgentIndex.vue')
      },
      {
        path: 'banks',
        name: 'agent-banks',
        props: true,
        component: () => import('@/pages/agents/agent/AgentBanks.vue')
      },
      {
        path: 'bank-details',
        name: 'agent-bank-details',
        props: true,
        component: () => import('@/pages/agents/agent/AgentBankDetails.vue')
      }
    ]
  },
  {
    path: '/managers',
    name: 'managers',
    meta: { permission: constants.permissions.managers },
    component: () => import('@/pages/managers/Managers.vue')
  },
  {
    path: '/managers/create',
    name: 'manager-create',
    meta: { permission: constants.permissions.managers },
    component: () => import('@/pages/managers/manager/ManagerCreate.vue')
  },
  {
    path: '/managers/:managerUuid',
    name: 'manager',
    props: true,
    meta: { permission: constants.permissions.managers },
    component: () => import('@/pages/managers/manager/Manager.vue')
  },
  {
    path: '/directors-ebc',
    name: 'directors-ebc',
    meta: { permission: constants.permissions.directorsEbc },
    component: () => import('@/pages/directors-ebc/DirectorsEbc.vue')
  },
  {
    path: '/directors-ebc/create',
    name: 'director-ebc-create',
    meta: { permission: constants.permissions.directorsEbc },
    component: () => import('@/pages/directors-ebc/director-ebc/DirectorEbcCreate.vue')
  },
  {
    path: '/directors-ebc/:directorEbcId',
    name: 'director-ebc',
    props: true,
    meta: { permission: constants.permissions.directorsEbc },
    component: () => import('@/pages/directors-ebc/director-ebc/DirectorEbc.vue')
  },
  {
    path: '/bank-directors',
    name: 'bank-directors',
    meta: { permission: constants.permissions.bankDirectors },
    component: () => import('@/pages/bank-directors/BankDirectors.vue')
  },
  {
    path: '/bank-directors/create',
    name: 'bank-director-create',
    meta: { permission: constants.permissions.bankDirectors },
    component: () => import('@/pages/bank-directors/bank-director/BankDirectorCreate.vue')
  },
  {
    path: '/bank-directors/:bankDirectorId',
    name: 'bank-director',
    props: true,
    meta: { permission: constants.permissions.bankDirectors },
    component: () => import('@/pages/bank-directors/bank-director/BankDirector.vue')
  },
  {
    path: '/employees',
    name: 'employees',
    meta: { permission: constants.permissions.employees },
    component: () => import('@/pages/employees/Employees.vue')
  },
  {
    path: '/employees/create',
    name: 'employees-create',
    meta: { permission: constants.permissions.employees },
    component: () => import('@/pages/employees/employee/EmployeeCreate.vue')
  },
  {
    path: '/employees/:employeeId',
    name: 'employee',
    props: true,
    meta: { permission: constants.permissions.employees },
    component: () => import('@/pages/employees/employee/Employee.vue')
  },
  {
    path: '/bank-employees',
    name: 'bank-employees',
    meta: { permission: constants.permissions.bankEmployees },
    component: () => import('@/pages/bank-employees/BankEmployees.vue')
  },
  {
    path: '/bank-employees/create',
    name: 'bank-employee-create',
    meta: { permission: constants.permissions.bankEmployees },
    component: () => import('@/pages/bank-employees/bank-employee/BankEmployeeCreate.vue')
  },
  {
    path: '/bank-employees/:bankEmployeeId',
    name: 'bank-employee',
    props: true,
    meta: { permission: constants.permissions.bankEmployees },
    component: () => import('@/pages/bank-employees/bank-employee/BankEmployee.vue')
  },
  {
    path: '/billing-accounts',
    name: 'billing-accounts',
    meta: { permission: constants.permissions.billingAccounts },
    component: () => import('@/pages/billing-accounts/BillingAccounts.vue')
  },
  {
    path: '/billing-accounts/create',
    name: 'billing-accounts-create',
    meta: { permission: constants.permissions.billingAccounts },
    component: () => import('@/pages/billing-accounts/billing-account/BillingAccountCreate.vue')
  },
  {
    path: '/billing-accounts/:billingAccount',
    props: true,
    meta: { permission: constants.permissions.billingAccounts },
    component: () => import('@/pages/billing-accounts/billing-account/BillingAccountRoot.vue'),
    children: [
      {
        path: '/',
        name: 'billing-accounts-billingAccount',
        props: true,
        component: () => import('@/pages/billing-accounts/billing-account/BillingAccount.vue')
      },
      {
        path: 'documents',
        name: 'billing-accounts-billingAccount-documents',
        props: true,
        component: () =>
          import('@/pages/billing-accounts/billing-account/BillingAccountDocuments.vue')
      }
    ]
  },
  {
    path: '/bank-guarantees',
    name: 'bank-guarantees',
    meta: { permission: constants.permissions.bankGuarantees },
    component: () => import('@/pages/bank-guarantees/BankGuarantees.vue')
  },
  {
    path: '/bank-guarantees/create',
    name: 'bank-guarantees-create',
    meta: { permission: constants.permissions.bankGuarantees },
    component: () => import('@/pages/bank-guarantees/bank-guarantee/BankGuaranteeCreate.vue')
  },
  {
    path: '/bank-guarantees/:bankGuarantee',
    props: true,
    meta: { permission: constants.permissions.bankGuarantees },
    component: () => import('@/pages/bank-guarantees/bank-guarantee/BankGuaranteeRoot.vue'),
    children: [
      {
        path: '/',
        name: 'bank-guarantees-bankGuarantee',
        props: true,
        component: () => import('@/pages/bank-guarantees/bank-guarantee/BankGuarantee.vue')
      },
      {
        path: 'documents',
        name: 'bank-guarantees-bankGuarantee-documents',
        props: true,
        component: () => import('@/pages/bank-guarantees/bank-guarantee/BankGuaranteeDocuments.vue')
      },
      {
        path: 'banks',
        name: 'bank-guarantees-bankGuarantee-banks',
        props: true,
        component: () => import('@/pages/bank-guarantees/bank-guarantee/BankGuaranteeBanks.vue')
      },
      {
        path: 'bank-details',
        name: 'bank-guarantees-bankGuarantee-bank-details',
        props: true,
        component: () =>
          import('@/pages/bank-guarantees/bank-guarantee/BankGuaranteeBankDetails.vue')
      },
      {
        path: 'chat',
        name: 'bank-guarantees-bankGuarantee-chat',
        props: true,
        component: () => import('@/pages/bank-guarantees/bank-guarantee/chat/BankGuaranteeChat.vue')
      }
    ]
  },
  {
    path: '/credits',
    name: 'credits',
    meta: { permission: constants.permissions.credits },
    component: () => import('@/pages/credits/Credits.vue')
  },
  {
    path: '/credits/create',
    name: 'credits-create',
    meta: { permission: constants.permissions.credits },
    component: () => import('@/pages/credits/credit/CreditCreate.vue')
  },
  {
    path: '/credits/:credit',
    props: true,
    meta: { permission: constants.permissions.credits },
    component: () => import('@/pages/credits/credit/CreditRoot.vue'),
    children: [
      {
        path: '/',
        name: 'credits-credit',
        props: true,
        component: () => import('@/pages/credits/credit/Credit.vue')
      },
      {
        path: 'company-executive',
        name: 'credits-credit-company-executive',
        props: true,
        component: () => import('@/pages/credits/credit/CreditCompanyExecutive.vue')
      },
      {
        path: 'banks',
        name: 'credits-credit-banks',
        props: true,
        component: () => import('@/pages/credits/credit/CreditBanks.vue')
      },
      {
        path: 'documents',
        name: 'credits-credit-documents',
        props: true,
        component: () => import('@/pages/credits/credit/CreditDocuments.vue')
      },
      {
        path: 'bank-details',
        name: 'credits-credit-bank-details',
        props: true,
        component: () => import('@/pages/credits/credit/CreditBankDetails.vue')
      }
    ]
  },
  {
    path: '/cities',
    name: 'cities'
    // component: () => import('@/pages/Index.vue')
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/pages/companies/Companies.vue')
  },
  {
    path: '/consent',
    name: 'consent'
    // component: () => import('@/pages/Index.vue')
  },
  {
    path: '/customer-reminder',
    name: 'customer-reminder'
    // component: () => import('@/pages/Index.vue')
  },
  {
    path: '/directors',
    name: 'directors'
    // component: () => import('@/pages/Index.vue')
  },
  {
    path: '/mutual-calculations',
    name: 'mutual-calculations',
    meta: { permission: constants.permissions.mutualCalculations },
    component: () => import('@/pages/MutualCalculations.vue')
  },
  {
    path: '/settings/banks',
    name: 'settings-banks',
    meta: { permission: constants.permissions.banks },
    component: () => import('@/pages/settings/banks/Banks.vue')
  },
  {
    path: '/settings/statuses',
    name: 'settings-statuses',
    meta: { permission: constants.permissions.statuses },
    component: () => import('@/pages/settings/statuses/Statuses.vue')
  },
  {
    path: '/settings/documents',
    name: 'settings-documents',
    meta: { permission: constants.permissions.documents },
    component: () => import('@/pages/settings/documents/Documents.vue')
  },
  {
    path: '/settings/documents/create',
    name: 'settings-documents-create',
    meta: { permission: constants.permissions.documents },
    component: () => import('@/pages/settings/documents/DocumentsCreate.vue')
  },
  {
    path: '/settings/products',
    name: 'settings-products',
    meta: { permission: constants.permissions.products },
    component: () => import('@/pages/settings/products/Products.vue')
  },
  {
    path: '/settings/roles',
    name: 'settings-roles',
    meta: { permission: constants.permissions.roles },
    component: () => import('@/pages/settings/roles/Roles.vue')
  },
  {
    path: '/settings/permissions',
    name: 'settings-permissions',
    meta: { permission: constants.permissions.permissions },
    component: () => import('@/pages/settings/permissions/Permissions.vue')
  }
  // {
  //   path: '/',
  //   name: ''
  //   // component: () => import('@/pages/Index.vue')
  // },
  // {
  //   path: '/',
  //   name: ''
  //   // component: () => import('@/pages/Index.vue')
  // },
  // {
  //   path: '/',
  //   name: ''
  //   // component: () => import('@/pages/Index.vue')
  // },
  // {
  //   path: '/',
  //   name: ''
  //   // component: () => import('@/pages/Index.vue')
  // }
]

export function createRouter() {
  return new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) return savedPosition

      if (to.hash) return { selector: to.hash, behavior: 'smooth' }

      if (to.path === from.path) return savedPosition

      return { x: 0, y: 0 }
    }
  })
}
