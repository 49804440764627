import instance from './axios.js'

export default {
  users: {
    login: body => instance.post('auth/login', body),

    logout: () => instance.post('auth/logout'),

    getUser: () => instance.get('auth/user'),

    getStatistic: () => instance.get('users/statistic'),

    updateMyAccount: body => instance.patch('auth/user', body)
  },

  statistics: {
    getWidgets: () => instance.get('statistics/widgets'),

    getStatuses: body => instance.post('statistics/statuses', body)
  },

  banks: {
    getAll: () => instance.get('banks'),

    list: (params = {}) => instance.get('banks/list', { params }),

    getLoginPassword: bankSlug => instance.get(`banks/${bankSlug}/user-password`)
  },

  statuses: {
    getAll: () => instance.get('statuses'),

    list: (params = {}) => instance.get('statuses/list', { params })
  },

  cities: {
    list: () => instance.get('cities/lists')
  },

  documents: {
    list: () => instance.get('documents'),

    create: body => instance.post('documents', body),

    edit: (documentId, body) => instance.patch(`documents/${documentId}`, body),

    show: documentId => instance.get(`documents/${documentId}`),

    getMimetypes: () => instance.get('documents/mimetypes/list')
  },

  products: {
    create: body => instance.post('products', body)
  },

  roles: {
    getAll: () => instance.get('roles')
  },

  permissions: {
    getAll: () => instance.get('permissions')
  },

  billingAccounts: {
    getAll: params => instance.get('billing-accounts', { params }),

    getOne: uuid => instance.get(`billing-accounts/${uuid}`),

    getStatistic: () => instance.get('billing-accounts/statistic'),

    create: body => instance.post('billing-accounts', body),

    getBillingAccountBankStatus: (uuid, bank) =>
      instance.get(`billing-accounts/${uuid}/billing-account-bank-status/${bank}`),

    changeStatus: (uuid, body) => instance.patch(`billing-accounts/${uuid}/change-status`, body),

    getMutualCalculations: params =>
      instance.get('billing-accounts/mutual-calculations', { params }),

    getDocumentsList: () => instance.get('billing-accounts/documents/list'),

    uploadDocument: (uuid, documentId, body) =>
      instance.patch(`billing-accounts/${uuid}/documents/${documentId}`, body),

    requestNewDocument: (uuid, documentId, body) =>
      instance.patch(`billing-accounts/${uuid}/documents/${documentId}/request-new`, body)
  },

  companies: {
    getAll: params => instance.get('companies', { params }),

    getOne: (uuid, params) => instance.get(`companies/${uuid}`, { params }),

    getStatistic: () => instance.get('companies/statistic'),

    changeStatus: (uuid, body) => instance.patch(`companies/${uuid}/change-status`, body)
  },

  mobileCheckouts: {
    getOne: uuid => instance.get(`mobile-checkouts/${uuid}`)
  },

  bankGuarantees: {
    getSettingsBanks: params => instance.get('bank-guarantees/settings/banks', { params }),

    getSettingsDocuments: () => instance.get('bank-guarantees/settings/documents'),

    getAll: params => instance.get('bank-guarantees', { params }),

    getOne: bankGuaranteeUuid => instance.get(`bank-guarantees/${bankGuaranteeUuid}`),

    getChart: params => instance.get('bank-guarantees/chart', { params }),

    create: body => instance.post('bank-guarantees', body),

    update: (bankGuaranteeUuid, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}`, body),

    getPurchaseNumber: params => instance.get('bank-guarantees/purchase-number', { params }),

    getAttachments: bankGuaranteeUuid =>
      instance.get(`bank-guarantees/${bankGuaranteeUuid}/attachments`),

    uploadDocument: (bankGuaranteeUuid, documentId, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/documents/${documentId}`, body),

    uploadDocumentByClient: (bankGuaranteeUuid, documentId, body) =>
      instance.patch(
        `bank-guarantees/${bankGuaranteeUuid}/documents-by-client/${documentId}`,
        body
      ),

    deleteDocument: (bankGuaranteesUuid, documentId) =>
      instance.delete(`bank-guarantees/${bankGuaranteesUuid}/documents/${documentId}`),

    requestNewDocument: (bankGuaranteeUuid, documentId, body) =>
      instance.patch(
        `bank-guarantees/${bankGuaranteeUuid}/documents/${documentId}/request-new`,
        body
      ),

    getBanks: bankGuaranteeUuid => instance.get(`bank-guarantees/${bankGuaranteeUuid}/banks`),

    setBanks: (bankGuaranteeUuid, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/banks`, body),

    addBank: (bankGuaranteeUuid, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/bank`, body),

    editBankOfBankGuarantee: (bankGuaranteeUuid, bankSlug, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/bank/${bankSlug}`, body),

    removeBankFromBankGuarantee: (bankGuaranteeUuid, bankSlug) =>
      instance.delete(`bank-guarantees/${bankGuaranteeUuid}/bank/${bankSlug}`),

    updateBankStatus: (bankGuaranteeUuid, id, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/bankGuarantees-bank-status/${id}`, body),

    addSignatureLink: (bankGuaranteeUuid, id, body) =>
      instance.patch(
        `bank-guarantees/${bankGuaranteeUuid}/bankGuarantees-bank-status/${id}/signature-link`,
        body
      ),

    changeStatus: (bankGuaranteeUuid, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/fast-edit`, body),

    changeEmployee: (bankGuaranteeUuid, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/employee`, body),

    getBankDetails: bankGuaranteeUuid =>
      instance.get(`bank-guarantees/${bankGuaranteeUuid}/bank-details`),

    setBankDetails: (bankGuaranteeUuid, body) =>
      instance.post(`bank-guarantees/${bankGuaranteeUuid}/bank-details`, body),

    sendToNewAgent: (bankGuaranteeUuid, body) =>
      instance.post(`bank-guarantees/${bankGuaranteeUuid}/send-to-new-agent`, body),

    requestClientDocuments: (bankGuaranteeUuid, body) =>
      instance.patch(`bank-guarantees/${bankGuaranteeUuid}/request-client-documents`, body),

    getBankGuaranteeDocumentsByApplicationNumber: token =>
      instance.get(`bank-guarantees/get-documents-by-application-number/${token}`),

    getBankEmployees: bankEmployeeId =>
      instance.get(`bank-guarantees/${bankEmployeeId}/bank-employees`)
  },

  credits: {
    getAll: params => instance.get('credits', { params }),

    getOne: creditUuid => instance.get(`credits/${creditUuid}`),

    create: body => instance.post('credits', body),

    update: (creditUuid, body) => instance.patch(`credits/${creditUuid}`, body),

    addCompanyExecutive: (creditUuid, body) =>
      instance.post(`credits/${creditUuid}/company-executive`, body),

    getFounders: creditUuid => instance.get(`credits/${creditUuid}/founders`),

    banks: creditUuid => instance.get(`credits/${creditUuid}/banks`),

    getSettingsBanks: () => instance.get('credits/settings/banks'),

    getSettingsDocuments: creditUuid => instance.get(`credits/${creditUuid}/settings/documents`),

    setBank: (creditUuid, bankSlug) => instance.patch(`credits/${creditUuid}/banks/${bankSlug}`),

    removeBank: (creditUuid, bankSlug) =>
      instance.delete(`credits/${creditUuid}/banks/${bankSlug}`),

    updateBankStatus: (creditUuid, id, body) =>
      instance.patch(`credits/${creditUuid}/credits-bank-status/${id}`, body),

    getAttachments: creditUuid => instance.get(`credits/${creditUuid}/attachments`),

    uploadDocument: (creditUuid, documentId, body) =>
      instance.patch(`credits/${creditUuid}/documents/${documentId}`, body),

    deleteDocument: (creditUuid, documentId) =>
      instance.delete(`credits/${creditUuid}/documents/${documentId}`),

    requestNewDocument: (creditUuid, documentId, body) =>
      instance.patch(`credits/${creditUuid}/documents/${documentId}/request-new`, body),

    getBankDetails: creditUuid => instance.get(`credits/${creditUuid}/bank-details`),

    setBankDetails: (creditUuid, body) => instance.post(`credits/${creditUuid}/bank-details`, body)
  },

  agents: {
    getAll: params => instance.get('agents', { params }),

    list: () => instance.get('agents/list'),

    create: body => instance.post('agents', body),

    getOne: agentUuid => instance.get(`agents/${agentUuid}`),

    update: (agentUuid, body) => instance.patch(`agents/${agentUuid}`, body),

    getBanks: agentUuid => instance.get(`agents/${agentUuid}/banks`),

    setBanks: (agentUuid, body) => instance.patch(`agents/${agentUuid}/banks`, body),

    getBankDetails: agentUuid => instance.get(`agents/${agentUuid}/bank-details`),

    setBankDetails: (agentUuid, body) => instance.patch(`agents/${agentUuid}/bank-details`, body),

    sendEmail: agentUuid => instance.post(`agents/${agentUuid}/send-email`),

    sendAccess: (agentUuid, body) => instance.post(`agents/${agentUuid}/send-access`, body),

    getContacts: agentId => instance.get(`agents/${agentId}/contacts`),

    restore: agentUuid => instance.patch(`agents/${agentUuid}/restore`)
  },

  managers: {
    getAll: params => instance.get('managers', { params }),

    create: body => instance.post('managers', body),

    getOne: managerUuid => instance.get(`managers/${managerUuid}`),

    update: (managerUuid, body) => instance.patch(`managers/${managerUuid}`, body),

    restore: managerUuid => instance.patch(`managers/${managerUuid}/restore`)
  },

  directorsEbc: {
    getAll: params => instance.get('directors-ebc', { params }),

    getOne: employeeUuid => instance.get(`directors-ebc/${employeeUuid}`),

    create: body => instance.post('directors-ebc', body),

    update: (uuid, body) => instance.patch(`directors-ebc/${uuid}`, body),

    restore: agentUuid => instance.patch(`directors-ebc/${agentUuid}/restore`)
  },

  employees: {
    getAll: params => instance.get('employees', { params }),

    list: () => instance.get('employees/list'),

    getOne: employeeUuid => instance.get(`employees/${employeeUuid}`),

    create: body => instance.post('employees', body),

    update: (uuid, body) => instance.patch(`employees/${uuid}`, body),

    remove: (employeeId, params) => instance.delete(`employees/${employeeId}`, { params }),

    restore: employeeId => instance.patch(`employees/${employeeId}/restore`)
  },

  bankDirectors: {
    getAll: params => instance.get('bank-directors', { params }),

    list: () => instance.get('bank-directors/list'),

    getOne: employeeUuid => instance.get(`bank-directors/${employeeUuid}`),

    create: body => instance.post('bank-directors', body),

    update: (uuid, body) => instance.patch(`bank-directors/${uuid}`, body),

    restore: agentUuid => instance.patch(`bank-directors/${agentUuid}/restore`)
  },

  bankEmployees: {
    getAll: params => instance.get('bank-employees', { params }),

    list: params => instance.get('bank-employees/list', { params }),

    getOne: bankEmployeeId => instance.get(`bank-employees/${bankEmployeeId}`),

    create: body => instance.post('bank-employees', body),

    update: (bankEmployeeId, body) => instance.patch(`bank-employees/${bankEmployeeId}`, body),

    remove: (bankEmployeeId, params) =>
      instance.delete(`bank-employees/${bankEmployeeId}`, { params }),

    restore: bankEmployeeId => instance.patch(`bank-employees/${bankEmployeeId}/restore`)
  },

  data: {
    getAddress: params => instance.get('data/address', { params }),

    getBankByBik: bik => instance.get(`data/bank/${bik}/find-by-bik`),

    getDivisionCode: params => instance.get('data/passport/issued-by', { params }),

    getInn: (inn, params) => instance.get(`data/${inn}/inn`, { params })
  },

  chats: {
    create: body => instance.post('chats', body),

    getAll: params => instance.get('chats', { params }),

    getOneBy: params => instance.get('chats/get-chat-by', { params }),

    getChatMessages: chatId => instance.get(`chats/${chatId}/messages`),

    sendMessage: (chatId, body) => instance.post(`chats/${chatId}/messages`, body),

    uploadMessageFile: (chatId, body) =>
      instance.post(`chats/${chatId}/messages/upload-file`, body),

    bulkMarkNewMessages: (chatId, body) =>
      instance.patch(`chats/${chatId}/messages/bulk-mark-new-messages`, body),

    sendEmailNotification: (chatId, body) =>
      instance.post(`chats/${chatId}/send-email-notification`, body)
  },

  getOkvds: params => instance.get('okvds/search', { params })
}
