/**
 * Validator messages
 */
const MESSAGES = {
  required: 'Поле является обязательным',
  numeric: 'Поле должно быть числом',
  email: 'Введен некорректный Email',
  isCyrillic: 'Вы вводите латинские буквы',
  isValidDate: 'Дата задана некорректно',
  url: 'Некорректная ссылка',
  fileTypes: 'Неверный формат файла',
  fileSize: length => `Максимальный размер файла ${length / 1000000} МБ`,
  minLength: length => `Минимум ${length} символа`,
  maxLength: length => `Максимум ${length} символа`,
  sameAs: field => `Это поле не совпадает с ${field} полем`,
  minAmount: (min, message = 'руб') => `Минимальное значение ${min} ${message}.`
}

/**
 * Return messages for vuelidate
 *
 * @param field
 * @param errorMessage
 * @returns {string} | undefined
 */
export default (field, errorMessage = undefined) => {
  if (field.$error) {
    if (field.required !== undefined && !field.required) {
      return MESSAGES.required
    }

    if (field.numeric !== undefined && !field.numeric) {
      return MESSAGES.numeric
    }

    if (field.email !== undefined && !field.email) {
      return MESSAGES.email
    }

    if (field.isCyrillic !== undefined && !field.isCyrillic) {
      return MESSAGES.isCyrillic
    }

    if (field.isValidDate !== undefined && !field.isValidDate) {
      return MESSAGES.isValidDate
    }

    if (field.url !== undefined && !field.url) {
      return MESSAGES.url
    }

    if (field.fileTypes !== undefined && !field.fileTypes) {
      return MESSAGES.fileTypes
    }

    if (field.fileSize !== undefined && !field.fileSize) {
      return MESSAGES.fileSize(field.$params.fileSize.max)
    }

    if (field.minLength !== undefined && !field.minLength) {
      return MESSAGES.minLength(field.$params.minLength.min)
    }

    if (field.maxLength !== undefined && !field.maxLength) {
      return MESSAGES.maxLength(field.$params.maxLength.max)
    }

    if (field.sameAs !== undefined && !field.sameAs) {
      return MESSAGES.sameAs(field.$params.sameAs.eq)
    }

    if (field.minAmount !== undefined && !field.minAmount) {
      return MESSAGES.minAmount(field.$params.minAmount.min, field.$params.minAmount.message)
    }
  }

  if (errorMessage) return errorMessage

  return undefined
}
