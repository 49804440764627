export default {
  creditTypes: {
    revolving: {
      title: 'Оборотный',
      slug: 'revolving'
    },
    contract: {
      title: 'Контрактный',
      slug: 'contract'
    }
  },

  familyStatus: {
    married: {
      title: 'Женат/замужем',
      slug: 'married'
    },
    single: {
      title: 'Холост/не замужем',
      slug: 'single'
    }
  },

  sex: {
    male: {
      title: 'Мужской',
      slug: 'male'
    },
    female: {
      title: 'Женский',
      slug: 'female'
    }
  },

  companyTypes: {
    ip: 'ИП',
    ooo: 'ООО'
  },

  permissions: {
    statistics: 'statistics',
    directors: 'directors',
    directorsEbc: 'directors-ebc',
    agents: 'agents',
    managers: 'managers',
    employees: 'employees',
    bankDirectors: 'bank-directors',
    bankEmployees: 'bank-employees',
    cities: 'cities',
    bankGuarantees: 'bank-guarantees',
    billingAccounts: 'billing-accounts',
    companies: 'companies',
    mobileCheckouts: 'mobile-checkouts',
    credits: 'credits',
    leads: 'leads',
    mutualCalculations: 'mutual-calculations',
    banks: 'banks',
    permissions: 'permissions',
    roles: 'roles',
    statuses: 'statuses',
    documents: 'documents',
    products: 'products'
  },

  documents: {
    passport: 'Скан паспорта (2-3 стр.)',
    registration: 'Скан паспорта с пропиской',
    snils: 'СНИЛС'
  },

  // TODO: delete this property after refactoring
  documentsCredits: {
    passport: 'Паспорт Генерального директора (все страницы)',
    soglasiye: 'Согласие',
    vipiska: 'Выписка из счёта',
    vipiska51: 'Карточка 51 счета'
  },

  documentsBankGuarantees: {
    protocol: 'Протокол (решение) об избрании Единоличного Исполнительного Органа',
    charter: 'Устав + дополнения к уставу(в последней редакции)',
    annualFinancialStatements: 'Годовая бухгалтерская отчетность (2021 год)',
    quarterlyFinancialStatements: 'Годовая бухгалтерская отчетность (2022 год)',
    passport: 'Паспорт Единоличного Исполнительного Органа',
    document: 'Дополнительный документ'
  },

  bgType: {
    18: '44-ФЗ',
    11: '223-ФЗ',
    19: 'ПП РФ 615'
  },

  performances: {
    performance_contract: 'Исполнение контракта',
    performance_warranty_obligations: 'Исполнение гарантийных обязательств',
    participate_tender: 'На участие в тендере',
    return_advance: 'На возврат аванса',
    payment_guarantee: 'Гарантия платежа'
  },

  banks: {
    otkritiye: 'otkritiye',
    alphabank: 'alphabank',
    promsvyazbank: 'promsvyazbank',
    delobank: 'delobank',
    mkb: 'mkb',
    uralsib: 'uralsib',
    zenit: 'zenit',
    vtb: 'vtb',
    sberbank: 'sberbank',
    lockobank: 'lockobank',
    rosbank: 'rosbank',
    ubrir: 'ubrir',
    vostochniy: 'vostochniy'
  },

  statuses: {
    waitingProcessed: 'waiting-processed',
    inWork: 'in-work',
    rejectedDs: 'rejected-ds',
    accountOpen: 'account-open',
    notAnswered: 'not-answered',
    double: 'double',
    wrongData: 'wrong-data',
    documentCollection: 'document-collection',
    registered: 'registered',
    assignedCallCustomer: 'assigned-call-customer',
    appointment: 'appointment',
    failureClient: 'failure-client',
    withoutConfirmation: 'without-confirmation',
    rejected: 'rejected',
    onCompletion: 'on-completion',
    approved: 'approved',
    issued: 'issued',
    draft: 'draft',
    ebcChecking: 'ebc-checking',
    needsWork: 'needs-work',
    haveOffer: 'have-offer',
    awaitingPayment: 'awaiting-payment',
    bgReleased: 'bg-released',
    ebcRejected: 'ebc-rejected',
    signatureRequired: 'signature-required',
    bankConsideration: 'bank-consideration'
  },

  roles: {
    admin: 'admin',
    director: 'director',
    agent: 'agent',
    manager: 'manager',
    employee: 'employee',
    svyaznoy: 'svyaznoy',
    directorEbc: 'director-ebc',
    employeeEbc: 'employee-ebc',
    bankDirector: 'bank-director',
    bankEmployee: 'bank-employee'
  },

  rolesNames: {
    admin: 'Админ',
    director: 'Директор',
    agent: 'Агент',
    manager: 'Менеджер',
    employee: 'Сотрудник',
    svyaznoy: 'Связной',
    'employee-ebc': 'Сотрудник ЕБЦ',
    'director-ebc': 'Директор ЕБЦ',
    'bank-director': 'Директор Банка',
    'bank-employee': 'Сотрудник Банка'
  },

  products: {
    bankGuarantees: {
      title: 'Банковская Гарантия',
      slug: 'bankGuarantees',
      slugSingular: 'bankGuaranty',
      routeName: 'bank-guarantees'
    },
    billingAccounts: {
      title: 'Расчетный счет',
      slug: 'billingAccounts',
      slugSingular: 'billingAccount',
      routeName: 'billing-accounts'
    },
    companies: {
      title: 'Регистрация ИП/ООО',
      slug: 'companies',
      slugSingular: 'company',
      routeName: 'companies'
    },
    mobileCheckouts: {
      title: 'Мобильная касса',
      slug: 'mobileCheckouts',
      slugSingular: 'mobileCheckout',
      routeName: 'mobile-checkouts'
    },
    credits: {
      title: 'Кредит Бизнесу',
      slug: 'credits',
      slugSingular: 'credit',
      routeName: 'credits'
    }
  }
}
