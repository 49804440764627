import { jwtDecode } from 'jwt-decode'
import api from '@/plugins/api.js'

export default {
  namespaced: true,

  state: {
    token: undefined,
    authUser: {},
    role: {},
    permissions: []
  },

  mutations: {
    setAuthUser(state, authUser) {
      state.authUser = authUser
    },

    setRole(state, role) {
      state.role = role
    },

    setPermissions(state, permissions) {
      state.permissions = permissions
    },

    setToken(state, token) {
      state.token = token
    }
  },

  actions: {
    async logout({ commit }, logoutToServer = true) {
      if (logoutToServer) {
        await api.users.logout()
      }

      localStorage.removeItem('token')

      commit('setToken', undefined)
    },

    /**
     * Getting user by token
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getUser({ commit }) {
      try {
        const { data: authUser } = await api.users.getUser()
        commit('setAuthUser', authUser)
        commit('setRole', authUser.role)
        commit('setPermissions', authUser.role.permissions)
      } catch (e) {
        // if(e.response.status === 401) {
        //   await this.$axios.setToken(false)
        //   commit('clearToken')
        //
        //   this.$router.push({ name: 'login' })
        //   commit('setAuthUser', null)
        // }
      }
    },

    setTokenDispatch({ commit }, token) {
      localStorage.setItem('token', token)

      commit('setToken', token)
    },

    autoLogin({ dispatch }) {
      const token = localStorage.getItem('token')

      if (token) {
        dispatch('setTokenDispatch', token)
      }

      isValidToken(token) ? dispatch('setTokenDispatch', token) : dispatch('logout', false)
    }
  },

  getters: {
    isAuth: state => !!state.token,

    hasPermission: state => permission => state.permissions.includes(permission)
  }
}

function isValidToken(token) {
  if (!token) {
    return false
  }

  const jwtData = jwtDecode(token) || {}
  const expires = jwtData.exp || 0

  return new Date().getTime() / 1000 < expires
}
