<template>
  <VAppBar app color="teal">
    <RouterLink :to="{ name: 'index' }" class="logo" v-text="'Единый Банковский Центр'" />

    <VAppBarNavIcon class="white--text" @click.stop="$emit('input', !value)" />
  </VAppBar>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: true }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content {
  padding: 0 16px 0 0;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 256px;
  padding: 1rem 1.3rem;
  background-color: #007d71;
  font-family: 'Poiret One', cursive;
  text-decoration: none;
  font-size: 18px;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .logo {
    width: 100%;
    background-color: #219688;
  }
}
</style>
