<template>
  <VDialog v-model="dialog" max-width="600" scrollable>
    <VCard>
      <VCardTitle>Вы все еще здесь?</VCardTitle>

      <VCardText>
        <p>Мы заметили, что вы не проявляли активности в течение некоторого времени.</p>

        <p>
          Автоматический выход из системы через:
          <span class="error--text">{{ formattedTime }}</span>
        </p>
      </VCardText>

      <VCardActions>
        <VSpacer />

        <VBtn text color="error" @click="logoutMixin">Выход из системы</VBtn>

        <VBtn color="primary" @click="continueWorking">Продолжить работу</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ActivityChecker',

  data: () => ({
    dialog: false,
    timeoutId: undefined,

    remainingTime: 120,
    remainingTimeoutId: undefined
  }),

  computed: {
    formattedTime() {
      const duration = moment.duration(this.remainingTime, 'seconds')
      const minutes = Math.floor(duration.asMinutes())
      const seconds = duration.seconds()
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }
  },

  watch: {
    $route: {
      immediate: true,

      handler() {
        localStorage.setItem('login_timeout', String(moment().add(20, 'minutes').valueOf()))
      }
    }
  },

  created() {
    if (this.$store.getters['auth/isAuth']) {
      this.checkLoginTimeout()
    }
  },

  destroyed() {
    clearTimeout(this.timeoutId)

    clearInterval(this.remainingTimeoutId)

    localStorage.removeItem('login_timeout')
  },

  methods: {
    async checkLoginTimeout() {
      const loginTimeout = Number(localStorage.getItem('login_timeout'))

      const timeBeforeLogOut = moment(loginTimeout).add(-2, 'minutes').valueOf()

      const now = moment().valueOf()

      if (now > timeBeforeLogOut && now < loginTimeout) {
        this.startCountdown()
      }

      if (now > loginTimeout) {
        return
      }

      this.timeoutId = setTimeout(this.checkLoginTimeout, 240000)
    },

    startCountdown() {
      this.dialog = true

      if (this.remainingTimeoutId) {
        clearInterval(this.remainingTimeoutId)
      }

      this.remainingTimeoutId = setInterval(async () => {
        if (this.remainingTime > 0) {
          this.remainingTime--
        } else {
          clearInterval(this.remainingTimeoutId)
          this.remainingTimeoutId = undefined

          await this.logoutMixin()
        }
      }, 1000)
    },

    async continueWorking() {
      localStorage.setItem('login_timeout', String(moment().add(20, 'minutes').valueOf()))

      this.dialog = false

      this.remainingTime = 120

      await this.checkLoginTimeout()
    }
  }
}
</script>

<style scoped></style>
